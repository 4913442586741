import React, { useEffect, useState } from "react";
import "./sidebar.scss";
import { NavLink, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";

const SideBar = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [courses, setCourses] = useState([]);
  const [openCourseIndex, setOpenCourseIndex] = useState(null);

  const handleToggleSideBar = () => {
    if (window.innerWidth < 1200) {
      document.body.classList.toggle("toggle-sidebar");
    }
  };

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/user-analytics/user-courses-chapters/${currentUser.email}?collegeId=${currentUser.collegeId}`
        );
        setCourses(response.data.courses);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    if (currentUser) {
      fetchCourses();
    }
  }, [currentUser]);

  const getInitials = (fullName) => {
    const nameParts = fullName.split(" ");
    let initials = "";
    for (let part of nameParts) {
      if (part.length > 0) {
        initials += part[0].toUpperCase();
      }
    }
    return initials;
  };

  const handleCourseClick = (index) => {
    setOpenCourseIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      <aside id="sidebar" className="sidebar">
        <div className="user">
          <span>{currentUser ? getInitials(currentUser.name) : ""}</span>
          <h5>{currentUser ? currentUser.name : ""}</h5>
          <p>{currentUser ? currentUser.email : ""}</p>
        </div>
        <hr />
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <NavLink className="nav-link" to="/dashboard">
              <i className="bi bi-grid"></i>
              <span onClick={handleToggleSideBar}>Dashboard</span>
            </NavLink>
            {/* <NavLink className="nav-link" to="/cognition-analysis">
              <i className="bi bi-clipboard-data"></i>
              <span onClick={handleToggleSideBar}>Cognition Analysis</span>
            </NavLink> */}
          </li>
          {courses.map((course, index) => (
            <li className="nav-item" key={course.course}>
              <div
                className="nav-link"
                onClick={() => handleCourseClick(index)}
              >
                <i className="bi bi-journal"></i>
                <span onClick={handleToggleSideBar}>{course.course}</span>
                <i
                  className={`ms-auto ${
                    openCourseIndex === index ? "open" : ""
                  }`}
                ></i>
              </div>
              <ul
                className={`nav-content ${
                  openCourseIndex === index ? "show" : ""
                }`}
              >
                {course.chapters.map((chapter, idx) => (
                  <li key={chapter}>
                    <NavLink
                      to={`/dashboard/${encodeURIComponent(
                        course.course
                      )}/${encodeURIComponent(chapter)}`}
                      className="nav-link"
                    >
                      <i className="bi bi-book"></i>
                      <span onClick={handleToggleSideBar}>{chapter}</span>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </aside>
      <Outlet />
    </>
  );
};

export default SideBar;
