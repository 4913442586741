import React, { useEffect, useState } from "react";
import "./chapter.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import { useSelector } from "react-redux";
import Heading from "../../components/chapter/heading/Heading";
import PromotionalVideo from "../../components/chapter/promotional-video/PromotionalVideo";
import CourseInfo from "../../components/chapter/course-info/CourseInfo";
import Duration from "../../components/chapter/duration/Duration";
import Progress from "../../components/chapter/progress/Progress";
import Swipper from "../../components/chapter/swipper/Swipper";
import Meter from "../../components/chapter/meter/Meter";
import dataJson from "../../assests/data.json";

const Chapter = ({ courseName, chapterName }) => {
  const { currentUser } = useSelector((state) => state.user);
  const [data, setData] = useState(null);
  const [chapterData, setChapterData] = useState(null);
  const [firstLoginTime, setFirstLoginTime] = useState("");
  const [duration, setDuration] = useState(0);
  const [levelData, setLevelData] = useState(null);
  const [totalEmail, setTotalEmail] = useState(null);
  const [learningPoints, setLearningPoints] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchChapterData = () => {
      const foundChapter = dataJson.find(
        (chapter) => chapter.chapter === chapterName
      );
      if (foundChapter) {
        setChapterData(foundChapter);
        setLoading(false);
      } else {
        setError(new Error("Chapter not found in the data.json"));
        setLoading(false);
      }
    };
    fetchChapterData();
  }, [chapterName]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/course/name/${courseName}?collegeId=${currentUser.collegeId}`
        );
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [courseName, currentUser]);

  useEffect(() => {
    const fetchFirstLoginTime = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/user-analytics/${courseName}/${chapterName}/${currentUser.email}/earliest-chapter-start?collegeId=${currentUser.collegeId}`
        );
        if (response.data && response.data.earliestChapterStartTime) {
          const firstLoginDateTime = new Date(
            response.data.earliestChapterStartTime
          );
          const formattedDate = `${firstLoginDateTime.getFullYear()}-${String(
            firstLoginDateTime.getMonth() + 1
          ).padStart(2, "0")}-${String(firstLoginDateTime.getDate()).padStart(
            2,
            "0"
          )}`;
          setFirstLoginTime(formattedDate);
        } else {
          console.error("No firstLoginTime found in the response data");
        }
      } catch (error) {
        console.error("Error fetching first login time:", error);
      }
    };
    fetchFirstLoginTime();
  }, [courseName, chapterName, currentUser]);

  useEffect(() => {
    const fetchDuration = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/course/${currentUser.email}/${courseName}/duration/passed?collegeId=${currentUser.collegeId}`
        );
        setDuration(response.data.percentagePassed);
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchDuration();
  }, [currentUser, courseName]);

  useEffect(() => {
    const fetchCourseLevel = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/user-analytics/${courseName}/level?collegeId=${currentUser.collegeId}`
        );
        setLevelData(response.data.level);
      } catch (error) {
        console.error("Error fetching course level:", error);
      }
    };
    fetchCourseLevel();
  }, [courseName, currentUser]);

  useEffect(() => {
    const fetchTotalEmails = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/user-analytics/${courseName}/${chapterName}/emails?collegeId=${currentUser.collegeId}`
        );
        setTotalEmail(response.data.count);
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchTotalEmails();
  }, [courseName, chapterName, currentUser]);

  useEffect(() => {
    const fetchLearningPoints = async () => {
      const response = await axios.get(
        `${SERVER_URL}/course/${courseName}/${chapterName}/lplist?collegeId=${currentUser.collegeId}`
      );
      setLearningPoints(response.data);
    };
    fetchLearningPoints();
  }, [courseName, chapterName, currentUser]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString();
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="chapter-container">
      <Heading courseName={courseName} chapterName={chapterName} />
      <div className="body">
        <div className="left">
          <div className="promotional-video">
            <PromotionalVideo
              courseName={courseName}
              chapterName={chapterName}
            />
          </div>
          <div className="intro">
            <CourseInfo data={chapterData} />
          </div>
        </div>
        <div className="right">
          <div className="top">
            <div className="top-heading">
              <Duration firstLoginTime={firstLoginTime} duration={duration} />
              <div className="progress">
                <Progress duration={duration} />
              </div>
            </div>
          </div>
          <div className="middle">
            <h1>
              <span>Learning Points</span>
            </h1>
            <Swipper learningPoints={learningPoints} />
          </div>
          <div className="bottom">
            {data && (
              <Meter
                selectedCourseDuration={data && data.duration}
                updatedAt={formatDate(data.updatedAt)}
                totalEmail={totalEmail}
                levelData={levelData}
              />
            )}
            <p
              className={
                levelData === "Easy"
                  ? "level1"
                  : levelData === "Moderate"
                  ? "level2"
                  : levelData === "Hard"
                  ? "level3"
                  : ""
              }
            >
              Level:{" "}
              <span
                className={
                  levelData === "Easy"
                    ? "color-green"
                    : levelData === "Moderate"
                    ? "color-orange"
                    : levelData === "Hard"
                    ? "color-red"
                    : ""
                }
              >
                {levelData}
              </span>
            </p>
            <div className="course">
              <h2>
                A Course By: <h3>Valtep AI</h3>
              </h2>
            </div>
            <div className="requirements">
              <h1>requirements:</h1>
              {data && <p>{data.requirements}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chapter;
