import React from 'react';

const CourseInfo = ({data}) => {
  return (
    <>
      <h1>Course Info</h1>
      <h4>{data.heading}</h4>
      <p>{data.description}</p>
    </>
  );
}

export default CourseInfo
